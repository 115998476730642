<template>
    <div>
        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :color="'#aa945b'"
        :is-full-page="fullPage">
        </loading>
        <CRow>
            <CCol sm="7">
                <CCard>
                    <CCardBody>
                        <CCol sm="12"  v-if="selectedQuestions.length > 0">
                            <CDataTable
                                :items="selectedQuestions"
                                :fields="fields"
                                :noItemsView='{ noResults: "No Questions Selected", noItems: "No Questions Selected" }'
                                hover
                                sorter
                                >
                                  <template #question_text="{item, index}">
                                    <td>
                                      <!--Display Answer options for all the question type-->
                                        <multipleChoice  v-if="item.question_type == 'multiple_choice_question'" :question="item"></multipleChoice>
                                        <multipleBlanks  v-else-if="item.question_type == 'fill_in_multiple_blanks_question'" :question="item"></multipleBlanks>
                                        <multipleAnswers  v-else-if="item.question_type == 'multiple_answers_question'" :question="item"></multipleAnswers>
                                        <matching  v-else-if="item.question_type == 'matching_question'" :question="item"></matching>
                                        <shortAnswer  v-else-if="item.question_type == 'short_answer_question'" :question="item"></shortAnswer>
                                        <multipleDropDowns  v-else-if="item.question_type == 'multiple_dropdowns_question'" :question="item"></multipleDropDowns>
                                        <trueFalse  v-else-if="item.question_type == 'true_false_question'" :question="item"></trueFalse>
                                        <numerical  v-else-if="item.question_type == 'numerical_question'" :question="item"></numerical>
                                        <div style="margin-top:10px" v-if="item.tags.length">
                                            <div style="color:green; font-weight:bold">Tags:</div>
                                            <span v-for="(tag, t_index) in item.tags" :key="t_index" class="mr-2">
                                                <CBadge color="success" style="font-size:14px; cursor:pointer;margin-top:10px;">{{tag}}</CBadge>
                                            </span>
                                        </div>
                                    </td>
                                  </template>

                              </CDataTable>
                        </CCol>
                        <CCol sm="12" v-else>
                            Please select questions from questions page to allow bulk tagging
                        </CCol>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="5">
                <CCard>
                    <CCardHeader>
                        <b>Filter</b>
                    </CCardHeader>
                    <CCardBody>
                        <CCol sm="12" v-for="(value, index) in tags_filter" :key="index" class="mt-2">
                            <label><b>{{value.name}}</b></label>
                                  <multiselect
                                      tag-placeholder="Add this as new tag"
                                      placeholder="Search or add a tag"
                                      label="name"
                                      track-by="id"
                                      :options="value.items"
                                      text-field="name"
                                      :multiple="true"
                                      v-model="value.selected_filter"
                                      :taggable="true"
                                    ></multiselect>
                        </CCol>
                    </CCardBody>
                    <CCardFooter>
                         <CButton class="save-tag-button"
                           @click="saveTagging"             
                           :disabled="!selectedQuestions">
                              Save Tagging
                          </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<style>
.save-tag-button{
  background-color:#428bca;
  color:white
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'; 
import { mapState } from "vuex";
import Vue from 'vue'

// Components to display different Question Type
import multipleChoice from '../questions/multipleChoice';
import multipleBlanks from '../questions/multipleBlanks';
import multipleAnswers from '../questions/multipleAnswers';
import matching from '../questions/matching';
import shortAnswer from '../questions/shortAnswer';
import multipleDropDowns from '../questions/multipleDropDowns';
import trueFalse from '../questions/trueFalse';
import numerical from '../questions/numerical';

export default {
    middleware: "auth",
    name:'Bulk-Tagging',
          components: {
        Loading,
        multipleChoice,
        multipleBlanks,
        multipleAnswers,
        matching,
        shortAnswer,
        multipleDropDowns,
        trueFalse,
        numerical
    },
    data(){
        return{
            selectedTags: [],
            pick: "",
            fullPage:true,
            isLoading:false,
            fields:[
                { key: 'question_text', _style:'min-width:20px;', label:'Questions'},
            ]
        }
    },
    computed: {
            ...mapState({
                selectedQuestions: state => state.question.selectedQuestions,
                tags_filter: state => state.question.tags_filter,

            }),
    },
    async mounted(){
        if (this.$route.query.query_string) {
            let question_ids = this.$route.query.query_string;
            // console.log(question_ids)
            await this.$store
                .dispatch("question/fetchSelectedQuestions", {
                    selected: question_ids.split(",")
                })
                .then(() => console.log("bulk tagging ......"));
            
            // console.log(this.tags)
            // if(!this.tags){
            //     await this.$store.dispatch("tag/fetchTags", true);
            // }
        }
        console.log(this.selectedQuestions)

    },
    methods:{
        getFilters(){
            var obj = {}; 

            for(let i = 0; i < this.tags_filter.length; i++){
                obj[this.tags_filter[i].name] = this.tags_filter[i].selected_filter; 
            }
            
            return obj;
        },
        async saveTagging(){
            this.isLoading = true;
            let question_ids = this.$route.query.query_string;

            await this.$store
            .dispatch("question/updateSelectedQuestions", {
                questions_ids: question_ids.split(','),
                filters: this.getFilters()
            })
            .then(() => {
                this.isLoading = false;
                this.$router.go(-1);
            })
            .catch(error => {
            if (error.response) {
                Vue.notify({
                group: "notify",
                type: "error",
                text: error.response.error.message,
                max: 5,
                duration: 5000,
                speed: 300
                });
            } else {
                console.log("Problem submitting New Post", error);
            }
            });
                this.isLoading = false;

        }
    }
}
</script>